<template>
  <vx-card class="py-2">
    <div class="text-center">
      <h1>{{ funnel.reviewRequestHeading }}</h1>
      <p class="mt-3 text-lg">
        {{ funnel.reviewRequestContent }}
      </p>
    </div>


    <div class="pt-6 flex flex-col items-center">
      <ul>
        <li
          v-for="site in threePack"
          :key="site.id"
          class="pl-4 pr-2 py-2 bg-gray-200 my-2 rounded"
          style="min-width: 200px;"
        >
          <a
            href="#"
            @click.prevent="complete(site)"
          >
            <div class="flex items-center justify-between">
              <div>
                <img
                  :src="site.reviewSite.logo"
                  :alt="`Share on ${site.reviewSite.name}`"
                  class="w-8 inline-block"
                >
                <span class="pl-2 text-xs">{{ site.reviewSite.name }}</span>
              </div>
              <div class="pt-1">
                <feather-icon
                  icon="ChevronRightIcon"
                  svg-classes=""
                />
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </vx-card>
</template>

<script>
import { http } from '@/ky';

export default {
  name: 'RjPositiveReview',

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },

    threePack: {
      type: Array,
      required: true,
    },
  },

  methods: {
    async complete(site) {
      const payload = {
        id: this.survey.id,
        selectedReviewSiteId: site.reviewSite.id,
        status: 'Review site',
      };
      await http.patch(`surveys/${this.survey.id}`, { json: payload });
      window.location.href = site.newReviewUrl;
    },
  },
};
</script>
